import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image, ScrollView, Alert, TextInput, Linking, ActivityIndicator} from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import { collection, query, where, getDocs, getFirestore, addDoc, doc, deleteDoc, Timestamp, orderBy} from "firebase/firestore";
import { getDownloadURL, getStorage, uploadBytes, ref } from "firebase/storage";
import  * as DocumentPicker from 'expo-document-picker';
import { getAuth } from "firebase/auth";
import { useFocusEffect } from '@react-navigation/native';
import styles from './style';



export default function Links({ navigation }) {

    const [retorno2, setRetorno2] = useState([]);
    const [id, setId] = useState(null)
    const [open, setOpen] = useState(false);
    const [reload, setReload] = useState("");


    const [link, setLink] = useState("");
    const [link2, setLink2] = useState("");
 
    useEffect(() => {
        getResumos();
        getResumos2();
        setReload("");
        setOpen(false);
    }, [reload])

    const getResumos = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Links DAO");
        const q = query(citiesRef, where("link", "!=", ""));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                link: doc.data().link,
                atual: doc.data().atual
            };
            array.push(Obj)
        });

        setRetorno2(array)
    }

    const getResumos2 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Links DAO");
        const q = query(citiesRef, where("link", "!=", ""), where("atual", "==", "Sim"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
            setId(doc.id);
            setLink2(doc.data().link)
        });
    }

    const Insert = async () => {
        const db = getFirestore();
        const docRef = await addDoc(collection(db, "Links DAO"), {
            link: link,
            atual: "Sim"
            
        });

        if(link2.length > 0) {
            const docRef2 = await addDoc(collection(db, "Links DAO"), {
                link: link2,
                atual: "Não"
                
            });
                                                        
            await deleteDoc(doc(db, "Links DAO", id));
        }

        alert("Link Alterado", "...")
    
        setReload("Recarrega")      
    }


    useEffect(() => {
        getResumos();
        getResumos2()
    }, [])

    return (
        <>
            <View style={styles.container}>
                
                <View style={styles.header}>
                    
                    <View>
                        <Text style={styles.Title}>Link para envio de casos DAO</Text>
                    </View>
                       
                    <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                        <MaterialCommunityIcons
                            name="reply"
                            size={40}
                            color="#ffffff" />
                    </TouchableOpacity>
                    
                </View>
                
 
            {open ===  false &&
                <View style={{alignSelf: "center", marginBottom: 20, marginTop: 40}}>
                    <View style={{flexDirection: 'row'}}>
                        <TextInput 
                        style={{
                            alignItems: 'center', width: 500, marginTop: 30, padding: 10,
                            height: 60, marginLeft: "auto", marginRight: "auto", color: "#000", 
                            fontSize: 17, backgroundColor: "#FFF", borderRadius: 20
                        }}
                            placeholder="Inclua o link para a sessão deste mês..."
                            placeholderTextColor="#000"
                            type="text"
                            onChangeText={(text) => setLink(text)}
                            value={link} 
                            />
                    

                        <TouchableOpacity style={{backgroundColor: "#5077A1", width: 60, height: 60, borderRadius: 30, marginLeft: 20, marginTop: 30}} onPress={() => {Insert()}}>
                            <MaterialCommunityIcons
                                name="send-outline"
                                size={30}
                                color="#FFF"
                                style={{alignItems: "center", alignContent: "center", alignSelf: "center", marginTop: 13}}
                            />
                        </TouchableOpacity>
                    </View>
                </View>
            }


            <View style={{justifyContent: "center",  alignItems: "center"}}>
            <ScrollView horizontal={true} style={{flex: 1}}>
            {open ===  false &&
                <View style={{margin: 30, justifyContent: "center", alignItems: "center"}}>
                    <View style={{flexDirection: 'row',  }}>
                        <View style={{backgroundColor: "#FAEDB5", width: 700, height: 60, margin: 5, borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Links</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 400, height: 60, margin: 5, borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Atual</Text>
                        </View>
                    </View>

                    {retorno2 && retorno2.map((item) => {
                    return (
                        <>
                            <View style={{flexDirection: 'row', }}>
                                <View style={{backgroundColor: "#EDEDF4", width: 700, height: 60, margin: 5,borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, margin: 10, fontWeight: "bold"}}>{item.link}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 400, height: 60, margin: 5,borderRadius: 15, justifyContent: "center", alignItems: "center"}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, margin: 10, fontWeight: "bold"}}>{item.atual}</Text>
                                </View>

                            </View>
                        </>
                    )
                    })}
                </View>
            }
            </ScrollView>
            </View>

            </View>
        </>
    )

}