import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image } from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import { collection, query, where, getDocs, getFirestore} from "firebase/firestore";
import { useFocusEffect } from '@react-navigation/native';
import styles from './style';


export default function HomeGrupoDepoimento({ navigation }) {

    const [retorno1, setRetorno1] = useState([]);
    const [retorno2, setRetorno2] = useState([]);
    const [retorno3, setRetorno3] = useState([]);


    useEffect(() => {
        getResumos1();
        getResumos2();
        getResumos3();
    }, [])

    useFocusEffect(
        React.useCallback(() => {
            getResumos1();
            getResumos2();
            getResumos3();
        }, [])
      );

    const getResumos1 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", 'testemunho'), where("concluido", "==", "Ok"), where("diplo", "==", "Formula"), where("grupo", "==", "Grupo5"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                tipo: doc.data().tipo,
                midia: doc.data().midia,
                email: doc.data().email,
                arquivo: doc.data().arquivo,
                nome: doc.data().nome,
                dataAtual: doc.data().dataAtual,
                diplo: doc.data().diplo,
                grupo: doc.data().grupo,
                concluido: "Ok"
            };
            array.push(Obj)
        });

        setRetorno1(array)
    }

    const getResumos2 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", 'testemunho'), where("concluido", "==", "Ok"), where("diplo", "==", "Formula"), where("grupo", "==", "Grupo6"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                tipo: doc.data().tipo,
                midia: doc.data().midia,
                email: doc.data().email,
                arquivo: doc.data().arquivo,
                nome: doc.data().nome,
                dataAtual: doc.data().dataAtual,
                diplo: doc.data().diplo,
                grupo: doc.data().grupo,
                concluido: "Ok"
            };
            array.push(Obj)
        });

        setRetorno2(array)
    }

    const getResumos3 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", 'testemunho'), where("concluido", "==", "Ok"), where("diplo", "==", "Formula"), where("grupo", "==", "Grupo7"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                tipo: doc.data().tipo,
                midia: doc.data().midia,
                email: doc.data().email,
                arquivo: doc.data().arquivo,
                nome: doc.data().nome,
                dataAtual: doc.data().dataAtual,
                diplo: doc.data().diplo,
                grupo: doc.data().grupo,
                concluido: "Ok"
            };
            array.push(Obj)
        });

        setRetorno3(array)
    }


    return (

        <View style={styles.container}>

            <View style={styles.header}>
                <View>
                    <Text style={styles.Title}>Grupos</Text>
                </View>

                <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                    <MaterialCommunityIcons
                        name="reply"
                        size={40}
                        color="#ffffff" />
                </TouchableOpacity>

            </View>

            <View style={styles.divImgBack}>
                <Image 
                    style={styles.imgBack} source={{
                    uri: 'https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---BRANCO.png?alt=media&token=7645261d-d233-404b-bfd9-b61b172ce0d0',
                }} />
            </View>
            
            <View style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', marginBottom: 50, marginTop: 20 }}>
                <Text style={styles.titleMenu}>Selecione um grupo</Text>
            </View>

            <View style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', flexDirection: 'row', marginBottom: 70, maginTop: 100 }}>

                <TouchableOpacity style={{ flexDirection: 'row', marginTop: 30, margin: 45, }} onPress={() => navigation.navigate("Testemunho_Formula_Grupo5")}>
                    <Text style={retorno1.length >= 1 ? styles.MenuModulos2 : styles.MenuModulos}>Grupo 5</Text>
                </TouchableOpacity>

                <TouchableOpacity style={{ flexDirection: 'row', marginTop: 30, margin: 45, }} onPress={() => navigation.navigate("Testemunho_Formula_Grupo6")}>
                    <Text style={retorno2.length >= 1 ? styles.MenuModulos2 : styles.MenuModulos}>Grupo 6</Text>
                </TouchableOpacity>

                <TouchableOpacity style={{ flexDirection: 'row', marginTop: 30, margin: 45, }} onPress={() => navigation.navigate("Testemunho_Formula_Grupo7")}>
                    <Text style={retorno3.length >= 1 ? styles.MenuModulos2 : styles.MenuModulos}>Grupo 7</Text>
                </TouchableOpacity>

            </View>

        </View>
    )
}