import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image, ScrollView, Alert, TextInput, Linking} from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import {Picker} from '@react-native-picker/picker'
import { collection, query, where, getDocs, getFirestore, addDoc, doc, deleteDoc, orderBy} from "firebase/firestore";
import { useFocusEffect } from '@react-navigation/native';
import styles from './style';



export default function GeralIngles({ navigation }) {

    const [retorno, setRetorno] = useState([]);
    const [retorno2, setRetorno2] = useState([]);
    const [relatorio, setRelatorio] = useState([]);
    const [email, setEmail] = useState("");
    const [diplo, setDiplo] = useState("");
    const [cas, setCas] = useState("");
    const [open, setOpen] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const [openG, setOpenG] = useState(false);
    const [openD, setOpenD] = useState(false);
    const [openP, setOpenP] = useState(false);
    const [openS, setOpenS] = useState(false);

    const [grupo, setGrupo] = useState("");
    const [diplomado, setDiplomado] = useState("");
    const [prof, setProf] = useState("");
    const [status, setStatus] = useState("");
    
    const getResumos = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", 'CASO'), where("data", "!=", null), orderBy("data"), where("ifoUsuario.diplo", "==", "Intercept Course"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                tipo: "CASO",
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
            };
            array.push(Obj)
        });

        const arrayOrdenado = OrdenaArray(array);

        setRetorno(arrayOrdenado)
    }

    const getResumosGrupo = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", 'CASO'), where("data", "!=", null), where("ifoUsuario.grupo", "==", grupo), orderBy("data"), where("ifoUsuario.diplo", "==", "Intercept Course"));
        let array = [];

        setRetorno([])

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                tipo: "CASO",
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
            };
            array.push(Obj)
        });

        const arrayOrdenado = OrdenaArray(array);

        setRetorno(arrayOrdenado)
    }

    //console.log(retorno)
    const getResumosDiplo = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", 'CASO'), where("data", "!=", null), where("ifoUsuario.diplo", "==", diplomado), orderBy("data"), where("ifoUsuario.diplo", "==", "Intercept Course"));
        let array = [];

        setRetorno([])

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                tipo: "CASO",
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
            };
            array.push(Obj)
        });

        const arrayOrdenado = OrdenaArray(array);

        setRetorno(arrayOrdenado)
    }
    
    const getResumosRecebido = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", 'CASO'), where("Envio", "!=", ""), orderBy("Envio"), where("ifoUsuario.diplo", "==", "Intercept Course"));
        let array = [];

        setRetorno([])

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                tipo: "CASO",
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
            };
            array.push(Obj)
        });

        const arrayOrdenado = OrdenaArray(array);

        setRetorno(arrayOrdenado)
    }

    const getResumosValidado = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", 'CASO'), where("validata", "!=", ""), orderBy("validata"), where("ifoUsuario.diplo", "==", "Intercept Course"));
        let array = [];

        setRetorno([])

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                tipo: "CASO",
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
            };
            array.push(Obj)
        });

        const arrayOrdenado = OrdenaArray(array);

        setRetorno(arrayOrdenado)
    }

    const getResumosInciado = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", 'CASO'), where("initData", "!=", ""), orderBy("initData"), where("ifoUsuario.diplo", "==", "Intercept Course"));
        let array = [];

        setRetorno([])

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                tipo: "CASO",
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
            };
            array.push(Obj)
        });

        const arrayOrdenado = OrdenaArray(array);

        setRetorno(arrayOrdenado)
    }

    const getResumosProf = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", 'CASO'), where("Prof", "==", prof), where("ifoUsuario.diplo", "==", "Intercept Course"));
        let array = [];

        setRetorno([])

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                tipo: "CASO",
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
            };
            array.push(Obj)
        });

        const arrayOrdenado = OrdenaArray(array);

        setRetorno(arrayOrdenado)
    }


    const getResumosPlanej = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", 'CASO'), where("Planej", "!=", ""), orderBy("Planej"), where("ifoUsuario.diplo", "==", "Intercept Course"));
        let array = [];

        setRetorno([])

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                tipo: "CASO",
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
            };
            array.push(Obj)
        });

        const arrayOrdenado = OrdenaArray(array);

        setRetorno(arrayOrdenado)
    }

    const getResumosStatus = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", 'CASO'), where("status", "==", status), where("ifoUsuario.diplo", "==", "Intercept Course"));
        let array = [];
        let array2 = [];

        setRetorno([])

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                tipo: "CASO",
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
            };

            const Obj2 = {
                Email: doc.data().email,
                Nome: doc.data().ifoUsuario.nome,
                Grupo: doc.data().ifoUsuario.grupo,
                Diplomado: doc.data().ifoUsuario.diplo,
                Status: doc.data().status,
                Numero: doc.data().numeroCaso,
                Validado: doc.data().validata,
            };
            array.push(Obj)
            array2.push(Obj2)

        });

        const arrayOrdenado = OrdenaArray(array);

        setRetorno(arrayOrdenado)
        setRelatorio(array2)
    }

    const getResumos2 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("email", "==", email), where("numeroCaso", "==", cas), where("ifoUsuario.diplo", "==", diplo), orderBy("email"), where("ifoUsuario.diplo", "==", "Intercept Course"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
            };
            array.push(Obj)
        });

        const arrayOrdenado = OrdenaArray(array);

        setRetorno2(arrayOrdenado)
    }

    const Extracao = async () => {

        fetch('https://sheetdb.io/api/v1/iq73mpx80chcx/all', {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((data) => console.log(data));

        setTimeout(() => {
            fetch('https://sheetdb.io/api/v1/iq73mpx80chcx', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    data: relatorio
                })
            })
            .then((response) => response.json())
            .then((data) => console.log(data));
        }, 3000);

        Linking.openURL("https://docs.google.com/spreadsheets/d/12tYKHSo8Klu-y08LdPx5f3QB3CvVcZ9q6Z13QRKsJAE/edit#gid=0");

    }


    useEffect(() => {
        getResumos();
    }, [])

    useEffect(() => {
        getResumos2();
    },[email])

    useFocusEffect(
        React.useCallback(() => {
        getResumos();
        }, [])
      );

      function OrdenaArray(array) {
        
        const otherElements = array.filter(item => item.tipo === 'CASO' || item.Numero !== 0);
        
        // Ordenar o array otherElements por validata
        otherElements.sort((a, b) => {
            // Função para interpretar a string de validata como uma data
            const parseValidataDate = (validata) => {
                if (typeof validata !== 'string') {
                    return new Date(0); // Retorna data inválida se 'validata' não é uma string
                }
                const [day, month, year] = validata.split('/').map(Number);
                return new Date(year, month - 1, day); // Mês em JavaScript é 0-indexed
            };
        
            // Comparar as datas de validata
            const dateA = parseValidataDate(a.Envio);
            const dateB = parseValidataDate(b.Envio);
        
            // Comparar considerando primeiro o ano, depois o mês e por último o dia
            if (dateA.getFullYear() !== dateB.getFullYear()) {
                return dateA.getFullYear() - dateB.getFullYear();
            } else if (dateA.getMonth() !== dateB.getMonth()) {
                return dateA.getMonth() - dateB.getMonth();
            } else {
                return dateA.getDate() - dateB.getDate();
            }
        });
        
        // Combinar os arrays ordenados
        const arrayOrdenado = [...otherElements];
        
        return(arrayOrdenado);
    }


    return (
        <>
            <View style={styles.container}>
                
                <View style={styles.header}>
                    {open !== true ?
                    <View>
                        <Text style={styles.Title}>Casos Clínicos</Text>
                    </View>
                    :
                    <View>
                        <Text style={styles.Title}>Caso clínico do aluno</Text>
                    </View>
                    }
                    <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.navigate("Home")} >
                        <MaterialCommunityIcons
                            name="reply"
                            size={40}
                            color="#ffffff" />
                    </TouchableOpacity>

                    <TouchableOpacity style={{position: "absolute", top: 20, right: 60}} onPress={() => {navigation.navigate("FilaProfessorIngles")}}>
                        <Text style={{fontSize: 50, textAlign: 'center'}}>📚</Text>
                    </TouchableOpacity>

                    <View style={{position: "absolute", top: 30, right: 150}}>
                        <Text style={{fontSize: 30, textAlign: 'center', color: "#FFF", fontWeight: "bold"}}>Nº Casos: {retorno.length}</Text>
                    </View>

                </View>
                

                <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'row', marginTop: 30, marginBottom: 30 }}>
               
                    {open !== true ?
                        <>
                            <TouchableOpacity style={{ backgroundColor: "transparent", borderBottomWidth: 1, borderBottomColor: "#FFFFFF70", margin: 20 }} onPress={() => navigation.navigate("FilaIngles")}>
                                <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Aguardando validação</Text>
                            </TouchableOpacity>

                            <TouchableOpacity style={{
                                backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFF", margin: 20,
                            }} >

                                <Text style={{ color: "#FFF", fontSize: 25, fontWeight: "bold" }}>Planilha Geral</Text>
                            </TouchableOpacity>

                            <TouchableOpacity style={{
                                backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFFFFF70", margin: 20,}} 
                                onPress={() => navigation.navigate("ConsultaAlunoIngles")}>
                                <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Consulta de casos clínicos</Text>
                            </TouchableOpacity>
                            
                        </>
                        :
                        <View style={{justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'row', marginTop: 30, marginBottom: 30}}>
                            <TouchableOpacity onPress={() => {setOpen(false), setEmail(""), setCas("")}}>
                                <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold"}}>Planilha Geral</Text>
                            </TouchableOpacity>
                            <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold"}}> -{'>'} </Text>
                            <Text style={{ color: "#FFF", fontSize: 25, fontWeight: "bold"}}>Documentos Enviados</Text>
                        </View>
                    }

                </View>
                
                {relatorio.length !== 0 &&
                    <View style={{position: "absolute", right: 120, top: 80}}>
                        <TouchableOpacity style={{backgroundColor: "#08C955", width: 200, height: 60, borderRadius: 20, justifyContent: "center", alignItems: "center" }} onPress={() => {Extracao()}}>
                            <Text style={{textAlign: "center", fontSize: 19.5, color: "#FFF", fontWeight: "bold"}}>Extrair Relatório</Text>
                        </TouchableOpacity>
                    </View>
                }

                {open === true &&
                <View>
                    {retorno2 && retorno2.map((item) => {
                    return (
                        <>
                        
                            <View style={styles.divReturn}>
                                <Text style={{position: "absolute", top: 0, right: 0, marginRight: 25, marginTop: 17}}>Nº Caso Clínico: {item.numeroCaso}</Text>
                                <Text style={styles.textReturnTitle}>{item.nome}</Text>
                                <Text style={styles.textReturnEmail}>{item.email}</Text>
                                <Text style={styles.textReturnEmail2}>{item.Envio}        {item.diplo}</Text>
                            </View>

                            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', alignContent: 'center', marginBottom: 30, marginTop: 5 }}>
                                
                                <TouchableOpacity style={styles.buttonView1} onPress={() => {
                                        Linking.openURL(item.Anamnesis);
                                        }}>
                                        <Text style={styles.textView}>📄 Guía de Anamnesis</Text>
                                </TouchableOpacity>

                                <TouchableOpacity style={styles.buttonView2} onPress={() => {
                                        Linking.openURL(item.DocPaciente);
                                        }}>
                                        <Text style={styles.textView}>📄 Documentação do Paciente</Text>
                                </TouchableOpacity>

                                <TouchableOpacity style={styles.buttonView3} onPress={() => {
                                        Linking.openURL(item.CefalometriaPaciente);
                                        }}>
                                        <Text style={styles.textView}>📄 Cefalometría</Text>
                                </TouchableOpacity>
                                </View>

                        </>
                    )
                    })}
                </View>
            }

            {openModal === true &&
                <View style={styles.modal}>
                    <View style={{margin: 30, flexDirection: "row", justifyContent: "space-between"}}>

                        <Text style={{color: "#000", textAlign: "center", fontSize: 30, }}>Escolha do filtro</Text>
                        <TouchableOpacity style={styles.X} onPress={() => {setOpenModal(false), setOpenD(false), setOpenG(false), setOpenP(false), setOpenS(false)}}>
                            <Text style={styles.Text_X}>X</Text>
                        </TouchableOpacity>

                    </View>

                    <View style={styles.botaoModal}>

                        <Text style={{color: "#000", textAlign: "center", fontSize: 40}}>Deseja filtrar por:</Text>

                        {openG == true &&
                            <>
                                <Picker
                                    selectedValue={grupo}
                                    style={{margin: 20, width: 300, fontSize: 20, height: 60, padding: 10, borderStyle: "solid", borderWidth: 1, borderColor: '#000', backgroundColor: '#E7F4F0' }}
                                    onValueChange={(itemValue) => setGrupo(itemValue)}>

                                    <Picker.Item label="--" value="--" />
                                    <Picker.Item label="Grupo2" value="Grupo2" />
                                    <Picker.Item label="Grupo3" value="Grupo3" />
                                    <Picker.Item label="Grupo4" value="Grupo4" />
                                    <Picker.Item label="Grupo5" value="Grupo5" />
                                    <Picker.Item label="Grupo6" value="Grupo6" />
                                    <Picker.Item label="Grupo7" value="Grupo7" />
                                    <Picker.Item label="Grupo8" value="Grupo8" />
                                    <Picker.Item label="Grupo9" value="Grupo9" />
                                    <Picker.Item label="Grupo10" value="Grupo10" />
                                    <Picker.Item label="Grupo11" value="Grupo11" />
                                </Picker>
                        
                                <TouchableOpacity style={{backgroundColor: '#F00032', width: 150, height: 60, margin: 20, borderRadius: 5, justifyContent: 'center',alignItems: "center"}} onPress={() => {getResumosGrupo(), setOpenModal(false), setOpenG(false)}}>
                                    <Text style={{textAlign: 'center', color: "#FFF", fontSize: 20}}>Filtrar</Text>
                                </TouchableOpacity>
                            </>
                        }

                        {openD == true &&
                            <>
                                <Picker
                                    selectedValue={diplomado}
                                    style={{margin: 20, width: 300, fontSize: 20, height: 60, padding: 10, borderStyle: "solid", borderWidth: 1, borderColor: '#000', backgroundColor: '#E7F4F0' }}
                                    onValueChange={(itemValue) => setDiplomado(itemValue)}>

                                    <Picker.Item label="--" value="--" />
                                    <Picker.Item label="Intercept" value="Intercept" />
                                    <Picker.Item label="Formula" value="Formula" />
                                    <Picker.Item label="COE" value="COE" />
                                    <Picker.Item label="DAO" value="DAO" />
                                </Picker>
                        
                                <TouchableOpacity style={{backgroundColor: '#F00032', width: 150, height: 60, margin: 20, borderRadius: 5, justifyContent: 'center',alignItems: "center"}} onPress={() => {getResumosDiplo(), setOpenModal(false), setOpenD(false)}}>
                                    <Text style={{textAlign: 'center', color: "#FFF", fontSize: 20}}>Filtrar</Text>
                                </TouchableOpacity>
                            </>
                        }

                        {openP == true &&
                            <>
                                <Picker
                                    selectedValue={prof}
                                    style={{margin: 20, width: 300, fontSize: 20, height: 60, padding: 10, borderStyle: "solid", borderWidth: 1, borderColor: '#000', backgroundColor: '#E7F4F0' }}
                                    onValueChange={(itemValue) => setProf(itemValue)}>

                                    <Picker.Item label="--" value="--" />
                                    <Picker.Item label="Dra. Gabriela Leticia" value="Dra. Gabriela Leticia" />
                                    <Picker.Item label="Dra. Renata Kato" value="Dra. Renata Kato" />
                                    <Picker.Item label="Dra. Beatriz Lima" value="Dra. Beatriz Lima" />
                                    <Picker.Item label="Dr. Rodrigo" value="Dr. Rodrigo" />
                                    <Picker.Item label="Dra. Ivanna" value="Dra. Ivanna" />
                                    <Picker.Item label="Dra. Adriana" value="Dra. Adriana" />
                                    <Picker.Item label="Dra. Rayane Pinto" value="Dra. Rayane Pinto" />
                                    <Picker.Item label="Dra. Olga Benário" value="Dra. Olga Benário" />
                                </Picker>
                        
                                <TouchableOpacity style={{backgroundColor: '#F00032', width: 150, height: 60, margin: 20, borderRadius: 5, justifyContent: 'center',alignItems: "center"}} onPress={() => {getResumosProf(), setOpenModal(false), setOpenP(false)}}>
                                    <Text style={{textAlign: 'center', color: "#FFF", fontSize: 20}}>Filtrar</Text>
                                </TouchableOpacity>
                            </>
                        }

                        {openS == true &&
                            <>
                                <Picker
                                    selectedValue={status}
                                    style={{margin: 20, width: 300, fontSize: 20, height: 60, padding: 10, borderStyle: "solid", borderWidth: 1, borderColor: '#000', backgroundColor: '#E7F4F0' }}
                                    onValueChange={(itemValue) => setStatus(itemValue)}>

                                    <Picker.Item label="--" value="--" />
                                    <Picker.Item label="Esperando validación de soporte" value="Esperando validación de soporte" />
                                    <Picker.Item label="Reprobado por los profesores" value="Reprobado por los profesores" />
                                    <Picker.Item label="Reprobado por el soporte" value="Reprobado por el soporte" />
                                    <Picker.Item label="Esperando análisis de los profesores" value="Esperando análisis de los profesores" />
                                    <Picker.Item label="Planejamento en curso" value="Planejamento en curso" />
                                    <Picker.Item label="Concluido" value="Concluido" />
                                 
                                </Picker>
                        
                                <TouchableOpacity style={{backgroundColor: '#F00032', width: 150, height: 60, margin: 20, borderRadius: 5, justifyContent: 'center',alignItems: "center"}} onPress={() => {getResumosStatus(), setOpenModal(false), setOpenS(false)}}>
                                    <Text style={{textAlign: 'center', color: "#FFF", fontSize: 20}}>Filtrar</Text>
                                </TouchableOpacity>
                            </>
                        }

                  
                        
                    </View>
                </View>
            }
        
       
            <ScrollView horizontal={true} style={{flex: 1,}}>
            
            {open ===  false && openModal === false &&
                <View style={{marginTop: 30, marginLeft: 30, marginRight: 30,  alignContent: "center", maxHeight: "90vh", }}>
                <View style={{flexDirection: 'row'}}>
                        <View style={{backgroundColor: "#FAEDB5", width: 450, height: 60, margin: 5, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Nome do aluno</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 450, height: 60, margin: 5, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Email</Text>
                        </View>

                        <TouchableOpacity onPress={() => {setOpenModal(true), setOpenG(true)}}>
                            <View style={{backgroundColor: "#FAEDB5", width: 150, height: 60, margin: 5, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Grupo 🔎</Text>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => {setOpenModal(true), setOpenD(true)}}>
                            <View style={{backgroundColor: "#FAEDB5", width: 150, height: 60, margin: 5, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Diplomado 🔎</Text>
                            </View>
                        </TouchableOpacity>
                        <View style={{backgroundColor: "#FAEDB5", width: 130, height: 60, margin: 5, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>No. Caso Clínico</Text>
                        </View>

                        <TouchableOpacity onPress={() => {getResumosRecebido()}}>
                            <View style={{backgroundColor: "#FAEDB5", width: 170, height: 60, margin: 5, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Recebido Em: 🔎</Text>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => {getResumosValidado()}}>
                            <View style={{backgroundColor: "#FAEDB5", width: 170, height: 60, margin: 5, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Validado Em: (Suporte) 🔎</Text>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => {getResumosInciado()}}>
                            <View style={{backgroundColor: "#FAEDB5", width: 170, height: 60, margin: 5, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Iniciado Em: (Professores) 🔎</Text>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => {setOpenModal(true), setOpenP(true)}}>
                            <View style={{backgroundColor: "#FAEDB5", width: 200, height: 60, margin: 5, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Professor: 🔎</Text>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => {getResumosPlanej()}}>
                            <View style={{backgroundColor: "#FAEDB5", width: 170, height: 60, margin: 5, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Planejado Em:(Professores) 🔎</Text>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => {setOpenModal(true), setOpenS(true)}}>
                            <View style={{backgroundColor: "#FAEDB5", width: 200, height: 60, margin: 5, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Status: 🔎</Text>
                            </View>
                        </TouchableOpacity>

                        <View style={{backgroundColor: "#FAEDB5", width: 200, height: 60, margin: 5, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Postado no Facebook:</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 260, height: 60, margin: 5, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Arquivos Anexados: (Aluno)</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 260, height: 60, margin: 5, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Caso Planejado: (Professores)</Text>
                        </View>
                    </View>
                <ScrollView>
                    {retorno && retorno.map((item) => {
                    return (
                        <>
                            <View style={{flexDirection: 'row'}}>
                                <View style={{backgroundColor: "#EDEDF4", width: 450, height: 60, margin: 5, borderRadius: 15}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.nome}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 450, height: 60, margin: 5, borderRadius: 15}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.email}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 150, height: 60, margin: 5, borderRadius: 15}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{(item.grupo).replace(/Grupo(\d+)/g, "Grupo $1")}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 150, height: 60, margin: 5, borderRadius: 15}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 6, fontWeight: "bold"}}>{item.diplo}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 130, height: 60, margin: 5, borderRadius: 15}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.numeroCaso}</Text>
                                </View>

                               
                                <View style={{backgroundColor: "#EDEDF4", width: 170, height: 60, margin: 5, borderRadius: 15}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.Envio}</Text>
                                </View>
                                
                                
                                <View style={{backgroundColor: "#EDEDF4", width: 170, height: 60, margin: 5, borderRadius: 15}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.validata}</Text>
                                </View>
                               
                                
                                <View style={{backgroundColor: "#EDEDF4", width: 170, height: 60, margin: 5, borderRadius: 15}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.initData}</Text>
                                </View>
                             
                                <View style={{backgroundColor: "#EDEDF4", width: 200, height: 60, margin: 5, borderRadius: 15}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.Prof}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 170, height: 60, margin: 5, borderRadius: 15}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.Planej}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 200, height: 60, margin: 5, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 16, fontWeight: "bold"}}>{item.status}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 200, height: 60, margin: 5, borderRadius: 15}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.face}</Text>
                                </View>

                                <View style={{backgroundColor: "#5077A1", width: 260, height: 60, margin: 5, borderRadius: 15}}>

                                    <TouchableOpacity onPress={() => {
                                        setEmail(item.email),
                                        setCas(item.numeroCaso),
                                        setDiplo(item.diplo),
                                        setOpen(true)
                                    }}>
                                        <Text style={{color: "#FFF", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Documentos Enviados</Text>
                                    </TouchableOpacity>
                                </View>

                                {item.Arquivo !== "" ?
                                <TouchableOpacity onPress={() => { Linking.openURL(item.Arquivo) }}>
                                    <View style={{backgroundColor: "#5077A1", width: 260, height: 60, margin: 5, borderRadius: 15}}>
                                        <Text style={{color: "#FFF", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Clique para Ver</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <View style={{backgroundColor: "#5077A1", width: 260, height: 60, margin: 5, borderRadius: 15}}>
                                        <Text style={{color: "#FFF", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.Arquivo}</Text>
                                </View>
                                }

                            </View>
                        </>
                    )
                    })}
                </ScrollView>
                </View>
            }
            
            </ScrollView>
         
            
            </View>
        </>
    )

}