export var IMMUTABLE_ITERABLE = "@@__IMMUTABLE_ITERABLE__@@";
export var IMMUTABLE_RECORD = "@@__IMMUTABLE_RECORD__@@";
export var IMMUTABLE_LIST = "@@__IMMUTABLE_LIST__@@";
export var IMMUTABLE_MAP = "@@__IMMUTABLE_MAP__@@";
export function isIterable(x) {
  return !!(x && x[IMMUTABLE_ITERABLE]);
}
export function isRecord(x) {
  return !!(x && x[IMMUTABLE_RECORD]);
}
export function isImmutable(x) {
  return isIterable(x) || isRecord(x);
}
export function isList(x) {
  return !!(x && x[IMMUTABLE_LIST]);
}
export function isMap(x) {
  return !!(x && x[IMMUTABLE_MAP]);
}
export function shallowToJS(x, whitelist) {
  return isIterable(x) ? x.reduce(function (result, curr, key) {
    var newCurr = curr;

    if (whitelist && whitelist[key]) {
      newCurr = shallowToJS(curr);
    }

    result[key] = newCurr;
    return result;
  }, isList(x) ? [] : {}) : x;
}