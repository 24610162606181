import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image, TextInput, ScrollView, Linking } from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import styles from './style';
import * as Animatable from 'react-native-animatable'
import { collection, query, where, getDocs, getFirestore, orderBy, addDoc, doc, deleteDoc} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import firebase from "../../../../Config/firebaseconfig.js"


export default function Trabalhos_Reprovados_25_2({ navigation }) {

    const [retorno, setRetorno] = useState([]);
    const [retorno2, setRetorno2] = useState([]);
    const [url, setUrl] = useState("")
    const [email, setEmail] = useState([]);
    const [email2, setEmail2] = useState("");
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [email3, setEmail3] = useState("");
    const [nome, setNome] = useState("");

    const auth = getAuth();
    const user = auth.currentUser;

    const getUrl = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Url");
        const q = query(citiesRef, where("url", "!=", ""));
       
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());

            setUrl(doc.data().url)
        });
    }


    const getResumos = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", "Trabalho OX - Apresentação - Reprovado - 25"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                modalidade: doc.data().modalidade,
                area: doc.data().area,
                titulo: doc.data().titulo,
                chave: doc.data().chave,
                arquivo: doc.data().arquivo,
                nome: doc.data().nome,
                co1: doc.data().co1,
                co2: doc.data().co2,
                co3: doc.data().co3,
                co4: doc.data().co4,
                co5: doc.data().co5,
            };
            array.push(Obj)
        });

        setRetorno(array)
    }

    const getResumos2 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", "Trabalho OX - Apresentação - Reprovado - 25"), where("email", "==", email));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                modalidade: doc.data().modalidade,
                area: doc.data().area,
                titulo: doc.data().titulo,
                chave: doc.data().chave,
                arquivo: doc.data().arquivo,
                nome: doc.data().nome,
                co1: doc.data().co1,
                co2: doc.data().co2,
                co3: doc.data().co3,
                co4: doc.data().co4,
                co5: doc.data().co5,
            };

            array.push(Obj)
        });

        setRetorno2(array)
    }

    useEffect(() => {
        getResumos();
        getUrl();
        if (user !== null) {
            user.providerData.forEach((profile) => {
                setEmail3(profile.email)
            });
        } 
    }, [])

    const getNome = async () => {
        
        const db = getFirestore();
        
        const citiesRef = collection(db, "Usuarios");
        const q = query(citiesRef, where("email", "==", email3));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
        
            setNome(doc.data().nome);
        });
    
}

    useEffect(() => {
        getResumos2();
    },[email])

    useEffect(() => {
        getNome();
    },[email3])

    useEffect(() => {
        getResumos();
    }, [open])

    return (

        <LinearGradient style={styles.container}
                    start={{ x: 0, y: 1 }}
                    end={{ x: 1, y: 0 }}
                    colors={['#1E0337', '#49036F', '#3E0979','#270979', '#371EAA', '#8B00B2',]}>
        
        <View style={{flex: 1}}>
            <View style={styles.header}>
                <View>
                    <Text style={styles.Title}>Trabajos OX - 2025</Text>
                </View>

                <TouchableOpacity style={styles.buttomMenu} onPress={() =>navigation.navigate("Trabalho25")} >
                    <MaterialCommunityIcons
                        name="reply"
                        size={40}
                        color="#ffffff"
                        style={{justifyContent: 'center', alignContent: 'center'}}
                    />
                </TouchableOpacity>

                <View style={styles.navBar}>

                    <TouchableOpacity style={styles.NavDesable} onPress={() => navigation.navigate("Trabalho25")}>
                        <Text style={styles.textNavDesable}>Em Espera</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.NavDesable} onPress={() => navigation.navigate("Trabalhos_Aprovados25")}>
                        <Text style={styles.textNavDesable}>Aprovados</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.NavActive}>
                        <Text style={styles.textNavActive}>Reprovados</Text>
                    </TouchableOpacity>
                </View>

                <View style={styles.navBar_2}>

                    <TouchableOpacity style={styles.NavDesable}onPress={() => navigation.navigate("Trabalhos_Reprovados25")}>
                        <Text style={styles.textNavDesable}>1º envio (word)</Text> 
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.NavActive}>
                        <Text style={styles.textNavActive}>2º envio (powerpoint)</Text>
                    </TouchableOpacity>

                </View>
                
            </View>

            {open === true &&
                <View>
                    {retorno2 && retorno2.map((item) => {
                    return (
                        <>

                            <TouchableOpacity style={styles.buttonFechar} onPress={() => {setOpen(false)}}>
                                <Text style={styles.textButtonFechar}>Fechar</Text>
                            </TouchableOpacity>

                        
                            <View style={styles.divReturn}>
                                <Text style={styles.textReturnTitle}>{item.titulo}</Text>
                                
                                <Text style={styles.textReturnName}>Autor: {item.nome}</Text>
                                <Text style={styles.textReturnEmail}>Email do Autor: {item.email}</Text>

                                { item.co1 !== "" &&
                                    <View style={{marginTop: 30, marginBottom: 20}}>

                                        <Text style={styles.textReturnNameCo}>Co-Autor 1: {item.co1}</Text>
                                        {item.co2 !== "" &&
                                            <Text style={styles.textReturnNameCo}>Co-Autor 2: {item.co2}</Text>
                                        }

                                        {item.co3 !== "" &&
                                            <Text style={styles.textReturnNameCo}>Co-Autor 3: {item.co3}</Text>
                                        }

                                        {item.co4 !== "" &&
                                            <Text style={styles.textReturnNameCo}>Co-Autor 4: {item.co4}</Text>
                                        }

                                        {item.co5 !== "" &&
                                            <Text style={styles.textReturnNameCo}>Co-Autor 5: {item.co5}</Text>
                                        }
                                    </View>
                                }

                            <View style={styles.divBottom}>
                                <TouchableOpacity style={styles.upload} onPress={() => {Linking.openURL(item.arquivo)}}>
                                    <Text style={styles.textUpload}>PowerPoint</Text>
                                </TouchableOpacity>

                            </View>

                            </View>

                        </>
                    )
                    })}
                </View>
            }

           {open === false &&
                <>
                    <View style={{flexDirection: 'row', justifyContent: "center", marginTop: 200}}>
                            <View style={{backgroundColor: "#FAEDB5", width: 520, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Nome do aluno</Text>
                            </View>
                            <View style={{backgroundColor: "#FAEDB5", width: 500, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Email</Text>
                            </View>
                            <View style={{backgroundColor: "#FAEDB5", width: 200, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Modalidae</Text>
                            </View>

                            <View style={{backgroundColor: "#FAEDB5", width: 340, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Area</Text>
                            </View>

                            <View style={{backgroundColor: "#FAEDB5", width: 260, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Documentação</Text>
                            </View>
                    
                        </View>

                    
                            <ScrollView 
                                showsVerticalScrollIndicator={false}
                                style={{marginBottom: 60}}
                            >

                                {retorno && retorno.map((item) => {
                                    return (
                                        <>
                                            <View style={{flexDirection: 'row', justifyContent: "center", cursor: "crosshair"}}>
                                                <View style={{backgroundColor: "#EDEDF4", width: 520, height: 60, margin: 5, borderRadius: 15, alignItems: 'center', justifyContent: 'center'}}>
                                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>{item.nome}</Text>
                                                </View>

                                                <View style={{backgroundColor: "#EDEDF4", width: 500, height: 60, margin: 5, borderRadius: 15, alignItems: 'center', justifyContent: 'center'}}>
                                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>{item.email}</Text>
                                                </View>

                                                <View style={{backgroundColor: "#EDEDF4", width: 200, height: 60, margin: 5, borderRadius: 15, alignItems: 'center', justifyContent: 'center'}}>
                                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>{item.modalidade}</Text>
                                                </View>

                                                <View style={{backgroundColor: "#EDEDF4", width: 340, height: 60, margin: 5, borderRadius: 15, alignItems: 'center', justifyContent: 'center'}}>
                                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>{item.area}</Text>
                                                </View>
                
                                                <TouchableOpacity style={{backgroundColor: "#0031E5", width: 260, height: 60, margin: 5, borderRadius: 15, alignItems: 'center', justifyContent: 'center'}}
                                                    onPress={() => {setOpen(true), setEmail(item.email)}}
                                                >
                                                    <Text style={{color: "#fff", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Visualizar</Text>
                                                </TouchableOpacity>
                                            
                                            </View>

                                        </>
                                )})}
                            </ScrollView>
                        </>
                        }
                    
            </View>
        </LinearGradient>
    )
}